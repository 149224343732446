<template>
  <v-container fluid class="content-wrapper">
    <!-- <h3 class="title-md">{{ $t("SigninPolicy.title") }}</h3> -->
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600 mb-0">{{ $t("SigninSeasonal.season_rate") }}</h4>
      <v-btn icon class="ml-3" @click="GetSeasonal()" :class="loadingData && 'animate__spining'" :disabled="loadingData">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>

    <!-- loading data -->
    <div v-if="loadingData">
      <v-skeleton-loader v-for="n in 2" :key="n" type="article"></v-skeleton-loader>
    </div>

    <!-- show data -->
    <div class="d-flex flex-column" v-else>
      <!-- <v-row row wrap justify="center" align="center" class="mt-3">
        <v-col cols="9" class="pa-0"> -->
          <div class="card card-shadow pa-5" style="height: auto;">
            <v-row row wrap>
              <v-col cols="3" align="start">
                <v-icon size="80">mdi-tag</v-icon>
              </v-col>
              <v-col cols="9" align="end">
                <p class="title-md">{{ $t("SigninSeasonal.customize") }}</p>
                <v-btn @click="AddSeason" dark depressed color="#666ee8">
                  {{ $t("SigninSeasonal.btn_add") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        <!-- </v-col>
      </v-row> -->
      <v-row row wrap>
        <v-col cols="12" v-for="(item, index) in all_seasons" :key="`seasonal_${index}`">
          <div class="card card-shadow pa-5">
            <v-row row wrap>
              <v-col cols="3" align="center" justify="center">
                <v-icon size="80">mdi-white-balance-sunny</v-icon>
              </v-col>
              <v-col cols="9" align="start">
                <div class="mb-2">
                  <span class="title-md-2">{{ item.seasonal_name }}</span>
                  <div class="rangedate mt-2">
                    <template v-for="(item, index) in item.date_slot">
                      <v-chip
                        small
                        :key="index"
                        v-if="item.date_past == false"
                        class="mb-2 mr-1"
                      >
                        {{ item.start_format }} - {{ item.end_format }}
                      </v-chip>
                      <!-- <v-chip
                      small
                      :key="index"
                      v-if="!item.date_past"
                      class="mb-2 mr-1"
                    >
                      {{ item.start_format }} - {{ item.end_format }}
                    </v-chip> -->
                    </template>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" align="end" style="margin-top: -3%">
                <v-btn
                  small
                  depressed
                  dark
                  color="#ff9149"
                  class="mr-2"
                  @click="UpdateSeason(item._id)"
                  >{{ $t("SigninSeasonal.btn_edit") }}</v-btn
                >
                <v-btn
                  small
                  depressed
                  dark
                  color="#ff4961"
                  @click="(dialogConfirm = true), (seasonDelete = item._id)"
                  >{{ $t("SigninSeasonal.btn_del") }}</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogConfirm" persistent max-width="300">
        <v-card>
          <v-card-title
            class="text-center"
            style="font-size: 1rem !important; letter-spacing: normal !important"
          >
            {{ $t("Alert.doyouwanttodelete") }}
          </v-card-title>
          <v-card-actions class="justify-center align-center pb-5">
            <v-btn
              depressed
              dark
              color="#e83e8c"
              class="pa-3 mr-3"
              @click="deleteSeason(seasonDelete)"
            >
              {{ $t("SigninGeneral.btn_delete") }}
            </v-btn>
            <v-btn color="pink" class="pa-3" outlined @click="dialogConfirm = false">
              {{ $t("SigninGeneral.btn_cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";

export default {
  name: "SigninSeasonalScreen",
  metaInfo: {
    title: "Seasonal",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else next();
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    all_seasons: {},
    dialogConfirm: false,
    seasonDelete: "",
    propertyData: null,
    loadingData: false,
  }),
  created() {
    EventBus.$emit("loadingtillend");
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    this.GetSeasonal();
  },
  mounted() {
    EventBus.$emit("endloading");
  },
  watch: {},
  methods: {
    async GetSeasonal() {
      this.loadingData = true;
      try {
        const res = await this.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${this?.propertyID || this?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization:
                this?.userToken ||
                localStorage?.getItem("Token") ||
                this?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || this?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // console.log("Get Success");
          var seasons = res.data.result[0].seasonal;
          this.propertyData = res.data.result[0];
          if (res.data.result[0].seasonal) {
            this.all_seasons = seasons
              .map((s) => {
                // console.log("-----", s.seasonal_name);
                s.date_slot.map((d) => {
                  var dateValue = d.end;
                  d.start_format = moment(d.start).locale("th").format("DD/MM/YYYY");
                  d.end_format = moment(d.end).locale("th").format("DD/MM/YYYY");
                  var currentDate = new Date(new Date().setUTCHours(0, 0, 0, 0));
                  var endDate = new Date(new Date(dateValue).setUTCHours(0, 0, 0, 0));
                  if (endDate < currentDate) {
                    d.date_past = true;
                  } else {
                    d.date_past = false;
                  }
                  // console.log(d.date_past);
                  return d;
                });
                return s;
              })
              .reverse();
            // console.log("All Season", this.all_seasons);
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        this.loadingData = false;
      }
    },
    AddSeason() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const item = {
        name: "SigninSeasonalAddScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      setTimeout(() => {
        EventBus.$emit("changePathname", item);
        EventBus.$emit("endloading");
      }, 1500);
    },
    UpdateSeason(seasonId) {
      EventBus.$emit("loadingtillend");
      const link = {
        name: "SigninSeasonalUpdateScreen",
        params: {
          propertyId: this.propertyID,
          userToken: this.userToken,
          seasonId: seasonId,
        },
      };
      setTimeout(() => {
        EventBus.$emit("changePathname", link);
        EventBus.$emit("endloading");
      }, 1500);
    },
    async deleteSeason(seasonID) {
      this.dialogConfirm = false;
      EventBus.$emit("loadingtillend");
      try {
        const res = await this.axios.delete(
          process.env.VUE_APP_API + "/property/seasonal?seasonal_id=" + seasonID,
          {
            headers: {
              Authorization:
                this?.userToken ||
                localStorage?.getItem("Token") ||
                this?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || this?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          swal(
            this.$t("Alert.success_title"),
            this.$t("Alert.delete_success"),
            this.$t("Alert.success_label"),
            {
              button: false,
              timer: 3000,
            }
          );
          this.seasonDelete = "";
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        // EventBus.$emit('endloading')
        this.GetSeasonal();
        // const item = {
        // name: 'SigninSeasonalScreen',
        // params: {
        //   propertyId: self.propertyID,
        //   userToken: self.userToken
        // }
      }
      // setTimeout(() => {
      //   EventBus.$emit('changePathname', item)
      //   EventBus.$emit('endloading')
      // }, 1500)
    },
    checkChangeData() {
      const self = this;
      var cantleavethispage = false;
      if (self.propertyData?.seasonal?.length !== self.all_seasons?.length)
        cantleavethispage = true;
      else cantleavethispage = false;
      return cantleavethispage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";
</style>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
</style>
